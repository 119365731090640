<template>
  <div class="providers">
    <div class="is-flex is-justify-content-flex-end">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="createProvider">
        Добавить поставщика
      </el-button>
    </div>
    <el-table :data="data">
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="name" label="Название" />
      <el-table-column prop="comment" label="Комментарий" :formatter="formatterText" />
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-edit" @click="updateProvider(scope.row)" />
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteProvider(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <provider-update ref="updateProviderRef" />
    <provider-create ref="createProviderRef" />
  </div>
</template>

<script>
import store from '@/app/app-state';
import { providersStoreModule, PROVIDERS_STORE_MODULE_NAME } from './providersStoreModule';
import { useProvidersList } from './useProvidersList';
import ProviderUpdate from './provider-update/provider-update.vue';
import ProviderCreate from './provider-create/provider-create.vue';

export default {
  name: 'ProvidersList',
  components: { ProviderUpdate, ProviderCreate },
  setup() {
    if (!store.hasModule(PROVIDERS_STORE_MODULE_NAME)) {
      store.registerModule(PROVIDERS_STORE_MODULE_NAME, providersStoreModule);
    }

    const {
      data,
      meta,
      changePage,
      deleteProvider,
      updateProviderRef,
      updateProvider,
      createProvider,
      createProviderRef,
      formatterText,
    } = useProvidersList();

    return {
      data,
      meta,
      changePage,
      deleteProvider,
      updateProviderRef,
      updateProvider,
      createProvider,
      createProviderRef,
      formatterText,
    };
  },
};
</script>
