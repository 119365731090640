<template>
  <el-dialog title="Обновление поставщика" :visible.sync="isOpen" width="40%">
    <el-form
      v-if="provider"
      ref="formUpdateRef"
      :model="provider"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="provider.name" />
      </el-form-item>
      <el-form-item label="Коментарий">
        <el-input v-model="provider.comment" type="textarea" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Cancel</el-button>
      <el-button type="primary" @click="saveProvider">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useProviderUpdate } from './useProviderUpdate';

export default {
  name: 'ProviderUpdate',
  setup() {
    const { isOpen, open, provider, saveProvider, rules, formUpdateRef } = useProviderUpdate();

    return {
      isOpen,
      open,
      provider,
      saveProvider,
      rules,
      formUpdateRef,
    };
  },
};
</script>
