import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { PROVIDERS_STORE_MODULE_NAME } from './providersStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useProvidersList() {
  const updateProviderRef = ref('');
  const createProviderRef = ref('');

  const formatterText = (v, row, value) => value || 'Не указано';

  const updateProvider = async (provider) => {
    const updateResult = await updateProviderRef.value.open(provider);
    if (updateResult) {
      Notification.success({
        title: 'Успех',
        message: 'Поставщик успешно обновлен',
      });
    }
  };

  const createProvider = async () => {
    const createResult = await createProviderRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Поставщик успешно добавлен',
      });
    }
  };

  const fetchProviders = (params) => {
    store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/fetchProviders`, params);
  };

  const deleteProvider = ({ id }) => {
    try {
      store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/deleteProvider`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Поставщик успешно удален.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  const meta = computed(() =>
    store.state[PROVIDERS_STORE_MODULE_NAME] ? store.state[PROVIDERS_STORE_MODULE_NAME].meta : {}
  );

  const data = computed(() =>
    store.state[PROVIDERS_STORE_MODULE_NAME] ? store.state[PROVIDERS_STORE_MODULE_NAME].data : []
  );

  const changePage = (page) => {
    router.replace({ query: { page } });
    fetchProviders({
      page,
    });
  };

  fetchProviders({
    page: routeData.query.page || 1,
  });

  return {
    meta,
    data,
    changePage,
    deleteProvider,
    updateProviderRef,
    updateProvider,
    createProvider,
    createProviderRef,
    formatterText,
  };
}
