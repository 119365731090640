import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { PROVIDERS_STORE_MODULE_NAME } from '../providersStoreModule';

export function useProviderUpdate() {
  const isOpen = ref(false);
  const provider = ref('');
  const formUpdateRef = ref(null);

  const rules = {
    name: [{ required: true, message: 'Укажите название поставщика', trigger: 'change' }],
  };

  let updateDialogController = null;

  const open = (p) => {
    provider.value = p;
    let resolve;
    let reject;
    const updateDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    updateDialogController = { resolve, reject };
    isOpen.value = true;
    return updateDialogPromise;
  };

  const saveProvider = () => {
    try {
      const { id, name, comment } = provider.value;
      formUpdateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/updateProviders`, {
            id,
            payload: { name, comment },
          });
          updateDialogController.resolve(true);
          isOpen.value = false;
          return;
        }
        return false;
      });
    } catch (e) {
      updateDialogController.reject(false);
      isOpen.value = false;
    }
  };

  return {
    isOpen,
    open,
    provider,
    saveProvider,
    rules,
    formUpdateRef,
  };
}
