import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { PROVIDERS_STORE_MODULE_NAME } from '../providersStoreModule';

export function useProviderCreate() {
  const isOpen = ref(false);
  const provider = ref({});
  const formСreateRef = ref(null);

  const rules = {
    name: [{ required: true, message: 'Укажите название поставщика', trigger: 'change' }],
  };

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveProvider = () => {
    try {
      const { name, comment } = provider.value;
      formСreateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/createProvider`, { name, comment });
          createDialogController.resolve(true);
          isOpen.value = false;
          provider.value = {};
          return;
        }
        return false;
      });
    } catch (e) {
      createDialogController.reject(false);
    }
  };

  return {
    isOpen,
    open,
    provider,
    saveProvider,
    rules,
    formСreateRef,
  };
}
